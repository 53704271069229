import '../less/index.less';
import autoComplete from '@tarekraafat/autocomplete.js';

var $ = jQuery.noConflict();
require('bootstrap');

$(function () {
	prepare_links();
	prepare_image_slider();
	prepare_autocompleters();
	prepare_filter_form();
	prepare_progress_bar();
	prepare_county_search();
});

function prepare_image_slider() {
	const $sliders = $('.flexslider');

	if ($sliders.length < 1) {
		return false;
	}

	// TODO: consider migrating to Swiper
	import('flexslider').then(() => {
		$sliders.flexslider({
			directionNav: false,
		});
	});
}

function prepare_filter_form() {
	const $form = $('#filter_form');

	if ($form.length != 1) {
		return false;
	}
	$form.find('input[type="submit"]').hide();

	// autocompleter inputs submit the form in prepare_autocompleters() function
	const $autocompleter_inputs = $form.find('input.ui_autocompleter');

	const $inputs = $form.find('input').not($autocompleter_inputs);

	$inputs.on('change', function () {
		$form.trigger('submit');
	});
}

function prepare_autocompleters() {
	const $autocomplete_fields = $('.ui_autocompleter');

	if ($autocomplete_fields.length == 0) {
		return false;
	}

	import('jquery-ui/ui/widgets/autocomplete').then(() => {
		$autocomplete_fields.each(function () {
			// get name of input
			let source_name = $(this).attr('name');
			source_name = source_name.replace('ac_', '');

			// check for local data source
			let source = '';
			if (ac_data != null && ac_data[source_name] != null) {
				source = ac_data[source_name];
			}
			// use remote source
			// TODO: need to finish remote source
			else {
				source = {};
			}

			// get the id of the input
			const id = $(this).attr('id');

			$(this)
				.autocomplete({
					autoFocus: true,
					source: source,
					minLength: 3,
					search: function () {
						// if hidden input has value, clear it
						if ($('#' + id + 'Id').val() != '') {
							$('#' + id + 'Id').val('');
						}
					},
					focus: function (_event, ui) {
						$('#' + id).val(ui.item.label);
						return false;
					},
					select: function (_event, ui) {
						// populate the text input with selection label
						$('#' + id).val(ui.item.label);

						// populate hidden input with selection id
						if ($('#' + id + 'Id').length) {
							$('#' + id + 'Id').removeAttr('disabled');
							$('#' + id + 'Id').val(ui.item.value);
						}

						// check for related fields
						if (ui.item.fields) {
							// update each related field with it's value
							for (let key in ui.item.fields) {
								$('#' + key).text(ui.item.fields[key]);
							}
						}

						$(this).closest('form').trigger('submit');
						return false;
					},
				})
				.blur(function () {
					if ($(this).val().length < 3) {
						// if user enters less than 3 characters blank out hidden input; the selection is not valid
						$('#' + id + 'Id').val('');
						$('#' + id + 'Id').attr('disabled', 'disabled');
					}
				});
		});
	});
}

function prepare_links() {
	// order matters for priority
	// add pdf icon to left of pdf links and open in a new window
	$('a[href$=".pdf"]')
		.filter(function () {
			const t = $(this).text();
			return t && t.trim() != '';
		})
		.addClass('icon_pdf')
		.on('click', function () {
			window.open($(this).attr('href'), 'pdfWindow');

			return false;
		});

	// add external icon to any link that are not on this domain and open in a new window
	// select all links that begin with http(s)://
	// remove internal links, staging links, links that have class if icon*
	// & links that have rel = "external"
	$('a[href^="http://"], a[href^="https://"]')
		.not(
			'[href*="kypartnership."], [href*=wpengine], [href*=localhost], [class*="icon"], [rel="external"]'
		)
		.addClass('icon_external')
		.attr('target', '_blank');
}

function stepChange() {
	const currentStep = $('.gf_step_active .gf_step_number').text();

	if (currentStep == '1') {
		$('.gf_step_clear').removeClass('step3');
		$('.gf_step_clear').removeClass('step2');
	} else if (currentStep == '2') {
		$('.gf_step_clear').removeClass('step3');
		$('.gf_step_clear').addClass('step2');
	} else if (currentStep == '3') {
		$('.gf_step_clear').removeClass('step2');
		$('.gf_step_clear').addClass('step3');
	}
}
function prepare_progress_bar() {
	if ($('.page-template-template-donation').length < 1) {
		return;
	}

	stepChange();

	$('.gform_page_footer .button').on('click', function () {
		stepChange();
	});
}

$.fn.pause = function (duration) {
	$(this).stop().animate(
		{
			dummy: 1,
		},
		duration
	);
	return this;
};

function prepare_county_search() {
	var countySearch = new autoComplete({
		selector: '.county_search',
		placeHolder: 'Enter a county to find your PCS region ...',
		data: {
			src: [
				'Adair County',
				'Allen County',
				'Anderson County',
				'Ballard County',
				'Barren County',
				'Bath County',
				'Bell County',
				'Boone County',
				'Bourbon County',
				'Boyd County',
				'Boyle County',
				'Bracken County',
				'Breathitt County',
				'Breckinridge County',
				'Bullitt County',
				'Butler County',
				'Caldwell County',
				'Calloway County',
				'Campbell County',
				'Carlisle County',
				'Carroll County',
				'Carter County',
				'Casey County',
				'Christian County',
				'Clark County',
				'Clay County',
				'Clinton County',
				'Crittenden County',
				'Cumberland County',
				'Daviess County',
				'Edmonson County',
				'Elliott County',
				'Estill County',
				'Fayette County',
				'Fleming County',
				'Floyd County',
				'Franklin County',
				'Fulton County',
				'Gallatin County',
				'Garrard County',
				'Grant County',
				'Graves County',
				'Grayson County',
				'Green County',
				'Greenup County',
				'Hancock County',
				'Hardin County',
				'Harlan County',
				'Harrison County',
				'Hart County',
				'Henderson County',
				'Henry County',
				'Hickman County',
				'Hopkins County',
				'Jackson County',
				'Jefferson County',
				'Jessamine County',
				'Johnson County',
				'Kenton County',
				'Knott County',
				'Knox County',
				'LaRue County',
				'Laurel County',
				'Lawrence County',
				'Lee County',
				'Leslie County',
				'Letcher County',
				'Lewis County',
				'Lincoln County',
				'Livingston County',
				'Logan County',
				'Lyon County',
				'Madison County',
				'Magoffin County',
				'Marion County',
				'Marshall County',
				'Martin County',
				'Mason County',
				'McCracken County',
				'McCreary County',
				'McLean County',
				'Meade County',
				'Menifee County',
				'Mercer County',
				'Metcalfe County',
				'Monroe County',
				'Montgomery County',
				'Morgan County',
				'Muhlenberg County',
				'Nelson County',
				'Nicholas County',
				'Ohio County',
				'Oldham County',
				'Owen County',
				'Owsley County',
				'Pendleton County',
				'Perry County',
				'Pike County',
				'Powell County',
				'Pulaski County',
				'Robertson County',
				'Rockcastle County',
				'Rowan County',
				'Russell County',
				'Scott County',
				'Shelby County',
				'Simpson County',
				'Spencer County',
				'Taylor County',
				'Todd County',
				'Trigg County',
				'Trimble County',
				'Union County',
				'Warren County',
				'Washington County',
				'Wayne County',
				'Webster County',
				'Whitley County',
				'Wolfe County',
				'Woodford County',
			],
			cache: true,
		},
		resultItem: {
			highlight: false,
		},
		events: {
			input: {
				selection: (event) => {
					countySearch.input.value = event.detail.selection.value;

					$('.county_search_form').submit();
				},
			},
		},
	});
}
